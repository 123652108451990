import Cookies from 'js-cookie'
import cookies from 'cookies'

export const S50_LANG_COOKIE = 'language'
export const S50_TOKEN_COOKIE = 'token'
export const S50_REFRESH_TOKEN_COOKIE = 'refresh_token'
export const COOKIE_SETTINGS_NAME = 'cookie_preferences'
export const S50_LOGOUT_COOKIE = 'logout'

export const TOKEN_AGE = 2592000
export const REFRESH_TOKEN_AGE = 34560000

export function getUserCookie(cookieName: string): string | number | any {
    return Cookies.get(cookieName)
}

export function geCookieSettings(): ICookieSettings {
    const cookieSettings = getUserCookie(COOKIE_SETTINGS_NAME)
    return typeof cookieSettings === 'string' ? JSON.parse(cookieSettings) : null
}

type SameSiteCookieAttribute = 'None' | 'Lax' | 'Strict'

interface ISetUserCookie {
    cookieName: string;
    value: string | Record<string, unknown>;
    domain?: string;
    expires?: number | Date;
    path?: string;
    sameSite?: SameSiteCookieAttribute;
    secure?: boolean;
}

export interface ICookieSettings {
    required: boolean;
    customization: boolean;
    analytical: boolean;
    marketing: boolean;
}

export function setTokenCookie(token: string, refreshToken: string) {
    const params = {
        domain: defineCookieDomain(),
        expires: new Date(new Date().getTime() + TOKEN_AGE * 1000),
        path: '/'
    }
    const paramsRefreshToken = {
        domain: defineCookieDomain(),
        expires: new Date(new Date().getTime() + REFRESH_TOKEN_AGE * 1000),
        path: '/'
    }
    setUserCookie({
        cookieName: S50_TOKEN_COOKIE,
        value: token,
        sameSite: process.env.NODE_ENV !== 'development' ? 'None' : 'Lax',
        secure: process.env.NODE_ENV !== 'development',
        ...params
    })
    setUserCookie({
        cookieName: S50_REFRESH_TOKEN_COOKIE,
        value: refreshToken,
        sameSite: process.env.NODE_ENV !== 'development' ? 'None' : 'Lax',
        secure: process.env.NODE_ENV !== 'development',
       ...paramsRefreshToken
    })
}

export function setUserCookie({
    cookieName,
    value,
    domain,
    expires,
    path,
    sameSite,
    secure
}: ISetUserCookie): void {
    if (cookieName === S50_LANG_COOKIE && !expires) {
        expires = 365 * 50
    }
    Cookies.set(cookieName, value, {
        ...domain ? { domain } : {},
        ...expires ? { expires } : {},
        ...path ? { path } : {},
        ...sameSite ? { sameSite } : {},
        ...secure ? { secure } : {} } )
}

export function defineCookieDomain(domain = ''): string {
    if (domain) {
        return domain
    }

    if (typeof window !== 'undefined') {
        domain = window.location.hostname.split('.').slice(-2).join('.')
    }

    return domain || '.clubee.local'
}

export async function removeCookie({ context, key }: { context?: any, key: string }) {
    if (context?.req && context?.res) {
        const cookie = new cookies(context.req, context.res)
        const cookieParams = {
            expires: new Date(0),
            overwrite: true,
            httpOnly: false,
            path: '/',
        }
        await cookie.set(key, 'deleted', cookieParams)
    }
}

export function removeAllCookies(domain: string): void {
    const cookies = Cookies.get()
    if (typeof cookies === 'object') {
        for (const property in cookies) {
            //remove cookies with setted domains and not
            Cookies.remove(property, { domain })
            Cookies.remove(property)
        }
    }
}