import { IMenuItem } from '@/shared/models/Menu'
import  { request } from './api'

export function getMe({ clubPrefix, token, context }: { clubPrefix: string, token: string, context: any }): Promise<any> {
    return request({
        endpoint: '/me',
        method: 'GET',
        clubPrefix,
        userToken: token,
        context,
        isPrivate: true
    })
}

export function getMenu({ clubPrefix }: { clubPrefix: string }): Promise<IMenuItem[]> {
    return request({
        endpoint: '/admin/menu',
        method: 'GET',
        clubPrefix,
        isPrivate: true,
    })
}

export function getOnboarding({ clubPrefix }: { clubPrefix: string }): Promise<IMenuItem[]> {
    return request({
        endpoint: '/me/onBoarding',
        method: 'GET',
        clubPrefix,
        isPrivate: true,
    })
}
