import  { request } from './api'
import { IClub, IClubItem } from '@/shared/models/Club';

export function getClub({ clubPrefix }: { clubPrefix: string }): Promise<IClub> {
    return request({
        endpoint: '/clubs',
        method: 'GET',
        clubPrefix
    })
}

// to display all the clubs that the user is in or one of his child accounts is in
export function getUserClubs({ clubPrefix }: { clubPrefix: string }): Promise<IClubItem[]> {
    const endpoint = '/me/clubs'

    return request({
        endpoint: endpoint,
        method: 'GET',
        clubPrefix,
        isPrivate: true,
    })
}

export function getClubService(clubId: number): Promise<IClub> {
    return request({
        endpoint: `/clubs/${clubId}`,
        method: 'GET',
    })    
}

export function getClubSocialNetworks({ clubPrefix }: { clubPrefix: string }): Promise<IClub> {
    return request({
        endpoint: '/socialnetworks',
        method: 'GET',
        clubPrefix
    })
}
