import  { request } from './api'
import { IWebsite, IColorItem, IWebsiteMode, IWebsiteFont, IWebsitePage, IWebsiteMenu } from '@/shared/models/Website'
import { ISection } from '@/shared/models/Section'

export function getWebsite({ clubPrefix }: { clubPrefix: string }): Promise<IWebsite> {
    return request({
        endpoint: '/websites',
        method: 'GET',
        clubPrefix
    })
}

export function getWebsiteModes({ clubPrefix }: { clubPrefix: string }): Promise<IWebsiteMode[]> {
    return request({
        endpoint: '/websites/modes',
        method: 'GET',
        clubPrefix
    })
}

export function getFont({ clubPrefix, id }: { clubPrefix: string; id: number }): Promise<IWebsiteFont> {
    return request({
        endpoint: `/fonts/${id}`,
        method: 'GET',
        clubPrefix
    })
}

export function getFonts({ clubPrefix }: { clubPrefix: string; }): Promise<[IWebsiteFont]> {
    return request({
        endpoint: `/fonts`,
        method: 'GET',
        clubPrefix
    })
}

export function getWebsiteColors({ clubPrefix }: { clubPrefix: string }): Promise<IColorItem[]> {
    return request({
        endpoint: '/colors',
        method: 'GET',
        clubPrefix
    })
}

export function getWebsiteMenu({ clubPrefix }: { clubPrefix: string }): Promise<IWebsiteMenu[]> {
    return request({
        endpoint: '/websites/menus',
        method: 'GET',
        clubPrefix
    })
}

export function getWebsitePages({ clubPrefix }: { clubPrefix: string }): Promise<IWebsitePage[]> {
    return request({
        endpoint: '/websites/pages',
        method: 'GET',
        clubPrefix,
    })
}

export function getWebsitePage({ clubPrefix, pageId, token }: { clubPrefix: string; pageId: number, token: string }): Promise<IWebsitePage> {
    return request({
        endpoint: `/websites/pages/${pageId}`,
        method: 'GET',
        clubPrefix,
        userToken: token
    })
}

export function getWebsitePageSections({ clubPrefix, pageId, token }: { clubPrefix: string; pageId: number | string, token: string }): Promise<ISection[]> {
    return request({
        endpoint: `/websites/pages/${pageId}/sections`,
        method: 'GET',
        clubPrefix,
        userToken: token
    })
}