import { isUndefined } from '@/shared/utils/lodashFunc'
import { getUserCookie, S50_REFRESH_TOKEN_COOKIE, S50_TOKEN_COOKIE, setTokenCookie, removeCookie, TOKEN_AGE, REFRESH_TOKEN_AGE } from 'utils/cookies'
import { retrieveServerCookie } from 'utils/language'
import Cookies from 'cookies'
import { getWebsite } from './websites';

interface IRequestKnowQuery {
    language?: string;
    _offset?: number;
    _limit?: number;
    users?: string;
    email?: string;
    sport?: number;
}

export interface IRequestQuery extends IRequestKnowQuery {
    [key: string]: any;
}

interface IRequestBody {
    [key: string]: any;
}

interface IRequestOptions {
    root?: string;
    endpoint: string;
    method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
    headers?: { [key: string]: any };
    query?: IRequestQuery;
    body?: IRequestBody;
    isPrivate?: boolean;
    clubPrefix?: string;
    userToken?: string;
    retryRefreshToken?: boolean
    context?: any
}

interface IPrepareRequestURL {
    endpoint: string;
    query?: IRequestQuery;
}

export const EXPIRED_TOKEN = 'expired_token'

function prepareRequestURL({
    endpoint,
    query,
}: IPrepareRequestURL): string {
    let url = `${process.env.NEXT_PUBLIC_API_ROOT}${endpoint}`

    if (process.env.NEXT_PUBLIC_API_USE_CACHE === 'true') {
        url = `${process.env.NEXT_PUBLIC_API_CACHE_ROOT}${endpoint}`
    }

    if (typeof window !== "undefined" && window.location.search.includes('preview=true')) {
        url = `${process.env.NEXT_PUBLIC_API_ROOT}${endpoint}`;
    }

    if (!query || isUndefined(query)) {
        // if (!query) {
        return url
    }

    Object.keys(query).map((param, index) => {
        // NOTE: this sintax is also converting arrays into flat strings
        // of comma separated values
        url += `${index === 0 ? '?' : '&'}${param}=${encodeURIComponent(query[param])}`
    })

    if (process.env.NODE_ENV === 'development') {
        console.log('Api request, url: ', url)
    }

    return url
}

export async function request({
    endpoint,
    method,
    headers,
    query,
    body,
    isPrivate,
    clubPrefix,
    userToken,
    retryRefreshToken = true,
    context,
}: IRequestOptions): Promise<Response | any> {

    if (typeof clubPrefix !== 'string' && process.env.NODE_ENV === 'development') {
        console.warn('Request with no clubPrefix (some endpoints don\'t need an x-website, so this might be ok), clubPrefix: ', clubPrefix, ', endpoint: ', endpoint, ', method: ', method)
    }

    const url = prepareRequestURL({
        endpoint: endpoint,
        query: query
    })

    let token = userToken || null

    const tokenResponse = await retrieveToken({ context, clubPrefix, providedToken: token, required: isPrivate })
    token = tokenResponse?.token
    const refreshToken = tokenResponse?.refresh_token

    return fetch(url, {
        method: method || 'GET',
        headers: {
            'Content-Type': 'application/json', //TODO check - if it is a file / FormData do not send this header
            ...clubPrefix ? { 'X-Website': clubPrefix } : {},
            ...token ? { 'Authorization': `Bearer ${token}` } : {},
            ...headers
        },
        body: JSON.stringify(body) //TODO check - if it is a file / FormData do not JSON.stringify
    })
        .then((response) => {
            if (response.status === 204) {
                return {
                    code: 204
                }
            }
            if (response.ok) {
                return response.json()
            }
            throw {
                code: response.status,
                message: response.statusText,
                url: response.url
            }
        })
        .catch(async (error) => {
            if (error?.code === 401 && refreshToken && retryRefreshToken) {
                removeCookie({ context, key: S50_TOKEN_COOKIE })
                const response = await getNewToken({ clubPrefix, refreshToken, context })
                return request({
                    endpoint,
                    method,
                    headers,
                    query,
                    body,
                    isPrivate,
                    clubPrefix,
                    userToken: response?.token,
                    retryRefreshToken: false
                })
            }

            if (process.env.NODE_ENV === 'development') {
                console.error('error: ', error)
                console.error('original query: ', query, 'clubPrefix: ', clubPrefix)
            }
            if (error?.code === 401 && !refreshToken) {
                removeCookie({ context, key: S50_TOKEN_COOKIE })
            }
            throw error
        })
}

async function retrieveToken({ context, clubPrefix, providedToken = '', required = false }: { context?: any, clubPrefix: string, providedToken?: string, required?: boolean }) {
    let token = providedToken
    let refreshToken
    if (context) {
        token = providedToken || retrieveServerCookie({ context, key: S50_TOKEN_COOKIE })
        refreshToken = retrieveServerCookie({ context, key: S50_REFRESH_TOKEN_COOKIE })
    } else if (typeof window !== 'undefined') {
        token = providedToken || getUserCookie(S50_TOKEN_COOKIE) as string
        refreshToken = getUserCookie(S50_REFRESH_TOKEN_COOKIE)
    }

    if (!token && !refreshToken && required) {
        throw {
            code: 401,
            message: 'Unauthorized'
        }
    }
    if (!token && refreshToken && required) {
        return getNewToken({ clubPrefix, refreshToken, context })
    }
    return {
        token,
        refresh_token: refreshToken
    }
}

async function getNewToken({ clubPrefix, refreshToken, context }: { clubPrefix: string, refreshToken: string, context?: any }) {
    try {
        const response = await request({
            endpoint: '/token/refresh',
            method: 'POST',
            retryRefreshToken: false,
            body: {
                'refresh_token': refreshToken
            },
            clubPrefix
        })

        const website = await getWebsite({ clubPrefix })

        let newToken: string
        let newRefreshToken: string
        if (response) {
            newToken = response.token
            newRefreshToken = response['refresh_token']
        }
        // persists to cookies or request query
        if (context?.query) {
            const cookies = new Cookies(context.req, context.res, { secure: process.env.NODE_ENV !== 'development' })
            // add to context.query so pages getServerSideProps can access
            context.query[S50_TOKEN_COOKIE] = newToken
            context.query[S50_REFRESH_TOKEN_COOKIE] = newRefreshToken

            const cookieParams = { maxAge: TOKEN_AGE * 1000, overwrite: true, httpOnly: false, sameSite: process.env.NODE_ENV !== 'development' ? 'None' : 'Lax', secure: process.env.NODE_ENV !== 'development' }
            const cookieParamsRefreshToken = { maxAge: REFRESH_TOKEN_AGE * 1000, overwrite: true, httpOnly: false, sameSite: process.env.NODE_ENV !== 'development' ? 'None' : 'Lax', secure: process.env.NODE_ENV !== 'development' }
            // update client cookie
            const domain = website.domain_online ? website.domain :
                context.req?.headers?.host ?
                    context.req?.headers?.host.split('.').slice(-2).join('.') :
                        null

            cookies.set(S50_TOKEN_COOKIE, newToken, { ...cookieParams, domain: process.env.NODE_ENV === 'development' ? 'clubee.local' : domain })
            cookies.set(S50_REFRESH_TOKEN_COOKIE, newRefreshToken, { ...cookieParamsRefreshToken, domain: process.env.NODE_ENV === 'development' ? 'clubee.local' : domain })
            // delete old cookie keys
            cookies.set(S50_TOKEN_COOKIE, '')
            cookies.set(S50_REFRESH_TOKEN_COOKIE, '')
        }

        if (typeof window !== 'undefined') {
            setTokenCookie(newToken, newRefreshToken)
        }
        return response
    } catch (error) {
        if (process.env.NODE_ENV === 'development') {
            console.error('error: ', error)
        }
        removeCookie({ context, key: S50_REFRESH_TOKEN_COOKIE })

        throw {
            code: 400,
            message: 'Bad Request'
        }
    }
}