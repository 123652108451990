import React from 'react'
import SnackbarContext, { IShowSnackbarProps } from 'contexts/snackbar'
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import MuiAlert from '@material-ui/lab/Alert';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ITheme } from '@/shared/styles/MuiTheme';

interface ISnackbarProps {
  children: React.ReactNode;
}

interface ISnackbarState extends IShowSnackbarProps {
  open: boolean;
  autoHideDuration: number
}

const snackbarInitState: ISnackbarState= {
  open: false,
  variant: 'success',
  autoHideDuration: 6000,
  message: '',
}

function SlideTransition(props: TransitionProps) {
  return <Slide {...props} direction='right' />;
}

const xsWidthMargin = 16
const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    minWidth: 320,
    [theme.breakpoints.down('xs')]: {
        width: '100%',
        maxWidth: `calc(100% - ${xsWidthMargin * 2}px)`,
    },
  },
}));
export default function SnackbarProvider({ children }: ISnackbarProps): React.ReactElement {
  const classes = useStyles()
  const [snackbarState, setSnackbarState] = React.useState<ISnackbarState>(snackbarInitState);
  function showSnackbar(props: IShowSnackbarProps) {
    const { autoHideDuration = 3000 } = props
    setSnackbarState({
      ...props,
      open: true,
      autoHideDuration,
    })
  }

  function hideSnackbar() {
    const { onClose } = snackbarState
    onClose?.()
    setSnackbarState(snackbarInitState)
  }

  const { open, autoHideDuration, variant, message } = snackbarState

  return(
      <SnackbarContext.Provider
        value={{
          showSnackbar,
          hideSnackbar
        }}
      >
        <Snackbar onClose={hideSnackbar} action={false} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={open} autoHideDuration={autoHideDuration} TransitionComponent={SlideTransition}>
          <MuiAlert  classes={classes} elevation={6} variant="filled" severity={variant}>{message}</MuiAlert>
        </Snackbar>
        {children}
      </SnackbarContext.Provider>
  )
}